import {LitElement, html} from 'lit'
import {classMap} from 'lit/directives/class-map.js'
import config from '../config'


function normalizeDate(d) {
  return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
}

function getMonthName(month) {
  const randomYear = 2000
  return new Date(randomYear, month -1).toLocaleString(undefined, {month: 'long'})
}

function getDayName(day) {
  const baseDate = new Date(Date.UTC(2017, 0, 2 + (day-1))) // known monday + day
  return baseDate.toLocaleDateString(undefined, {weekday: 'short'})
}


function parseDateString(date) {
  const dateReg = new RegExp(
    // start date
    /^(?<startYear>[\d]{2,4})[-/](?<startMonth>[\d]{1,2})[-/](?<startDay>[\d]{1,2})/.source

    // space separator
    + /\s+/.source

    // optional end date
    + /(?:(?<endYear>[\d]{2,4})[-/](?<endMonth>[\d]{1,2})[-/](?<endDay>[\d]{1,2}))?$/.source,
  )
  return dateReg.exec(date.trim())
}

export default class DateRangePicker extends LitElement {
  static formAssociated = true

  static get properties() {
    return {
      value:           {type: String},
      dropdownVisible: {type: Boolean},
      selection:       {type: Array},

      calendarDate:    {type: Date, attribute: false},
      selectionType:   {type: String, attribute: false},
    }
  }

  constructor() {
    super()
    this.value = ''
    this.selection = []
    this.dropdownVisible = false
    this.calendarDate = new Date()

    this._internals = this.attachInternals()

    this.selectionType = 'day' /* day or month */
  }

  _setFormValue(value) {
    const result = parseDateString(value)
    if (!result) {
      // invalid date range submitted
      this.value = ''
      this._internals.setFormValue('')
      return false
    }

    this.value = value
    this._internals.setFormValue(value)
    this.selection = [
      new Date(
        result.groups.startYear,
        result.groups.startMonth -1,
        result.groups.startDay,
      ),
      new Date(
        result.groups.startYear,
        result.groups.startMonth -1,
        result.groups.startDay,
      ),
    ]

    return true
  }

  connectedCallback() {
    super.connectedCallback()
    this._setFormValue(this.value)

    this.listeners = {}
    this.listeners.outsideclicklistener = (event) => {
      const isClicked = event.composedPath().includes(this)
      if (!isClicked) {
        this.dropdownVisible = false
      }
    }
    document.addEventListener('click', this.listeners.outsideclicklistener)
  }
  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener('click', this.listeners.outsideclicklistener)
  }

  updated(changedProps) {
    if (changedProps.has('value')) {
      const res = parseDateString(this.value)
      if (res) {
        this._internals.setFormValue(this.value)
      }
    } else if (changedProps.has('selection')) {
      const [start, end] = this.selection
      if (start && end) {
        this.value = `${normalizeDate(start)} ${normalizeDate(end)}`
      } else {
        this.value = ''
      }
    }
  }

  _setDropdownVisible(visible) {
    this.dropdownVisible = visible
  }

  render() {
    const classes = classMap({
      'dropdown': true,
      'is-active': this.dropdownVisible,
    })

    return html`
      <link rel="stylesheet" href="${config.GLOBAL_STYLE_URL}"/>
      <div class="${classes}" @click=${() => this._setDropdownVisible(true)}>
          <div class="control has-icons-right">
            <div class="input is-fullwidth"
                tabindex="0"
                .value=${this.value}
                style="text-transform: capitalize; white-space: nowrap; max-width: 30ch"
                @focus=${() => this._setDropdownVisible(true)}>
                <div style="overflow: scroll"> ${this._renderDateRange()} </div>
            </div>
            <span class="icon is-right has-text-link">
              <i class="fa fa-calendar-alt"></i>
            </span>
          </div>
        <div class="dropdown-menu">
          <div class="dropdown-content">
            <div class="dropdown-item" style="text-transform: capitalize">
              ${
                this.selectionType === 'day'
                  ? this._renderDayDropdownControl()
                  : this._renderMonthDropdownControl()
              }
            </div>
            <div class="dropdown-item">
              ${
                this.selectionType === 'day'
                  ? this._renderDayCalendar(this.calendarDate.getFullYear(), this.calendarDate.getMonth() + 1)
                  : this._renderMonthCalendar()
              }
            </div>
          </div>
        </div>
      </div>
    `
  }

  _setCalendarDate(year, month) {
    this.calendarDate = new Date(
      year || this.calendarDate.getFullYear(),
      month || this.calendarDate.getMonth(),
      1,
    )
  }

  _renderMonthDropdownControl () {
    const updateMonth = delta => {
      this._setCalendarDate(this.calendarDate.getFullYear() + delta)
    }
    const setMonthMode = () => {this.selectionType = 'day' }

    return html`
      <div class="field has-addons has-text-centered">
        <div class="control">
          <button type="button" class="button" @click=${() => updateMonth(-1)}>
            <span class="icon"><i class="fas fa-arrow-left"></i></span>
          </button>
        </div>
        <div class="control is-expanded">
          <span type="button" class="button is-flex" @click=${() => setMonthMode()}>
            ${this.calendarDate.toLocaleDateString(undefined, {year: 'numeric'})}
          </span>
        </div>
        <div class="control">
          <button class="button" type="button" @click=${() => updateMonth(+1)}>
            <span class="icon"><i class="fas fa-arrow-right"></i></span>
          </button>
        </div>
      </div>
    `
  }

  _renderDayDropdownControl() {
    const updateMonth = delta => {
      this.calendarDate = new Date(
        this.calendarDate.getFullYear(),
        this.calendarDate.getMonth() + delta,
        1,
      )
    }
    const setMonthMode = () => {this.selectionType = 'month' }

    return html`
      <div class="field has-addons has-text-centered">
        <div class="control">
          <button type="button" class="button" @click=${() => updateMonth(-1)}>
            <span class="icon"><i class="fas fa-arrow-left"></i></span>
          </button>
        </div>
        <div class="control is-expanded">
          <span type="button" class="button is-flex" @click=${() => setMonthMode()}>
            ${this.calendarDate.toLocaleDateString(undefined, {month: 'long', year: 'numeric'})}
          </span>
        </div>
        <div class="control">
          <button class="button" type="button" @click=${() => updateMonth(+1)}>
            <span class="icon"><i class="fas fa-arrow-right"></i></span>
          </button>
        </div>
      </div>
    `
  }

  _renderDateRange() {
    const [startDate, endDate] = this.selection

    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    }

    const startText = startDate ? startDate.toLocaleDateString(undefined, options) : html`&nbsp;&nbsp;`
    const endText = endDate ? endDate.toLocaleDateString(undefined, options) : html`&nbsp;&nbsp;`

    return html`${startText} <span class="has-text-grey-light"> &ndash; </span> ${endText}`
  }

  _renderMonthCalendar() {
    const months = [
      [1,   2,  3],
      [4,   5,  6],
      [7,   8,  9],
      [10, 11, 12],
    ].map(
      row => row.map(
        d => ({
          id: d,
          label: getMonthName(d),
        }),
      ),
    )

    return html`
      <table class="table">
        <thead></thead>
        <tbody>
          ${
            months.map(
              line => html`
                <tr>${line.map(month => this._renderMonth(month, month.id === this.calendarDate.getMonth() + 1))}</tr>
              `,
            )
          }
        </tbody>
      </table>
    `
  }

  _renderDayCalendar(year, month) {
    const lastDayOfMonth = new Date(year, month, 0).getDate()
    const offset = (new Date(year, month-1, 1).getDay() + 6) % 7

    const calendar = [...new Array(Math.ceil((lastDayOfMonth + offset) / 7) * 7)]

    for (let i = 1; i <= lastDayOfMonth; i += 1) {
      calendar[offset + i -1] = i
    }

    const weekCalendar = calendar.reduce((acc, value, idx) => {
      const week = Math.floor(idx / 7)
      const weekDays = acc[week] || []

      acc[week] = [...weekDays, value && new Date(year, month-1, value)]
      return acc
    }, [])

    const today = new Date()
    return html`
      <table class="table is-marginless is-size-7-mobile">
        <thead>
          <tr>
            <th> ${getDayName(1)} </th>
            <th> ${getDayName(2)} </th>
            <th> ${getDayName(3)} </th>
            <th> ${getDayName(4)} </th>
            <th> ${getDayName(5)} </th>
            <th class="has-text-info"> ${getDayName(6)} </th>
            <th class="has-text-info"> ${getDayName(7)} </th>
          </tr>
        </thead>
        <tbody>${
          weekCalendar.map(
            week => html`<tr>${week.map(
              (day, idx) =>
                this._renderDay(
                  day,
                  idx,
                  day && (
                    day.getFullYear() === today.getFullYear()
                    && day.getMonth() === today.getMonth()
                    && day.getDate() === today.getDate()
                  ),
                ),
            )}</tr>`,
          )
        }</tbody>
      </table>
    `
  }

  _select(day) {
    let [start, end] = this.selection

    if (start && end) {
      start = undefined
      end = undefined
    }

    if (!start) {
      this.selection = [day, undefined]
    } else if (day < start) {
      this.selection = [day, start]
    } else {
      this.selection = [start, day]
    }
  }

  _renderDay(day, idx, curday) {
    if (!day) {
      return html`<td class="has-background-light"></td>`
    }
    const dayNumber = day.getDate()
    const [start, end] = this.selection

    const selectionClasses = {}
    if ((start && day.getTime() === start.getTime()) || (end && day.getTime() === end.getTime())) {
      Object.assign(selectionClasses, {
        'has-background-link': true,
        'has-text-white': true,
      })
    } else if (start && end && start < day && day < end) {
      Object.assign(selectionClasses, {
        'has-background-link-light': true,
        'has-text-link': true,
      })
    } else if (curday) {
      Object.assign(selectionClasses, {
        'has-text-weight-bold': true,
        'has-text-primary': true,
        'has-background-primary-light': true,
      })
    }

    const classes = classMap({
      'has-text-centered': true,
      'is-clickable': true,

      ...selectionClasses,
    })
    return html`<td class="${classes}" @click="${() => this._select(day)}">${dayNumber}</td>`
  }


  _renderMonth({id, label}, selected) {
    const classes = classMap({
      'is-clickable': true,
      'has-text-centered': true,
      'is-selected': selected,
    })

    return html`
      <td class=${classes} style="text-transform: capitalize" @click=${() => this._setCalendarDate(undefined, id-1)}>
        ${label}
      </td>
    `
  }
}
