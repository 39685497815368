import {LitElement, html} from 'lit'
import config from '../config'
import measure from '../measure'
import events from '../events'


function _parseOptions(options) {
  const filterOptions = elem => ['OPTION', 'OPTGROUP'].includes(elem.tagName)
  const selectedItems = new Set()
  function parseOptgroup(opt) {
    if (opt.tagName === 'OPTION') {
      if (opt.selected) {
        selectedItems.add(opt.value)
      }
      return {
        key: opt.value,
        label: opt.text,
      }
    }

    return {
      label: opt.label,
      options: Array.from(opt.children).filter(filterOptions).map(parseOptgroup),
    }
  }

  return [options.filter(filterOptions).map(parseOptgroup), selectedItems]
}


export default class MultiSelect extends LitElement {
  static formAssociated = true

  static get properties() {
    return {
      placeholder: {type: String},
      name: {type: String},
      dropdownVisible: {type: Boolean, attribute: false},
      selectedItems: {attribute: false},
      regexp: {type: Object, attribute: false},
      dropdownInfo: {attribute: false},
      index: {type: Number, attribute: false},
      slottedValues: {type: Array, attribute: false},
      class: {type: String},
    }
  }

  constructor() {
    super()
    this._internals = this.attachInternals()

    this.dropdownVisible = false
    this.dropdownInfo = {}

    this.selectedItems = new Set()
    this.regexp = /./
    this.placeholder = ''
    this.repr = ''
    this.index = null
    this.slottedValues = []
  }

  // register outside click listener
  connectedCallback() {
    super.connectedCallback()

    // register slotchange listener
    this._slotListener = () => this._onSlotChange()
    this.shadowRoot.addEventListener('slotchange', this._slotListener)

    // close popup on click outside
    this._listener = document.addEventListener('click', event => {
      const isClicked = event.composedPath().includes(this)
      if (!isClicked) {
        this.dropdownVisible = false
      }
    })
  }

  _onSlotChange() {
    const elements = this.renderRoot.querySelector('slot').assignedElements()
    const parsed = _parseOptions(elements)
    this.slottedValues = parsed[0]
    this.selectedItems = parsed[1]
  }

  // remove outside click listener
  disconnectedCallback() {
    super.disconnectedCallback()
    this.shadowRoot.removeEventListener('slotchange', this._slotListener)
    document.removeEventListener('click', this._listener)
  }


  // toggle one or more keys
  _toggle(...keys) {
    const allSelected = keys.every(k => this.selectedItems.has(k))

    // if all are selected, remove keys from new selected items
    // otherwise add keys to selected items
    const newValues = (
      allSelected
        ? [...this.selectedItems].filter(i => !keys.includes(i))
        : [...this.selectedItems].concat(keys)
    )
    this.selectedItems = new Set(newValues)
  }


  updated(changedProps) {
    if (changedProps.has('dropdownVisible')) {
      if (this.dropdownVisible) {
        // dropdown opened
        const elem = this.renderRoot.querySelector('input[role="search"]')
        elem.focus()

        // reset index for keyboard selection
        this.index = null

        const $trigger = this.renderRoot.querySelector('.dropdown-trigger')
        const $dropdown = this.renderRoot.querySelector('.dropdown-content')
        const $search = this.renderRoot.querySelector('#search-bar')
        const measures = measure.getAvailableSpace($trigger)

        if (measures.below >= Math.max($dropdown.offsetHeight / 2, 200)) {
          this.dropdownInfo = {
            'classPosition': 'is-below',
            'availableSpace': measures.below - $search.offsetHeight - $dropdown.style.paddingTop,
          }
        } else {
          this.dropdownInfo = {
            classPosition: 'is-up',
            availableSpace: measures.above - $search.offsetHeight- $dropdown.style.paddingTop,
          }
        }

      } else {
        // dropdown closed
        const newRepr = this.value
        if (newRepr !== this.repr) {
          events.trigger('change', this)
          this.repr = newRepr
        }
        this.dropdownInfo = {
          'classPosition': 'is-below',
          'availableSpace': 200,
        }
      }
    }

    if (this.name && changedProps.has('selectedItems')) {
      const submitData = new FormData()
      for (const key of this.selectedItems) {
        submitData.append(this.name, key)
      }
      this._internals.setFormValue(submitData)
    }

  }

  _toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible
  }

  _onKeydown(event) {
    // const values = this.slottedValues.flatMap(option => ['nomegruppo', ...option.options] || option)
    const values = this.slottedValues.map(g => this._filterGroup(g)).flatMap(elem => {
      if ('options' in elem) {
        return [elem, ...elem.options]
      } else {
        return elem
      }
    })
    events.handleIndexKeyboardEvent(
      event, this.index, values.length -1,
      {
        setIndex: (value) => {
          this.index = value

          const $el = this.renderRoot.querySelector('#items').children[this.index]
          if ($el) {$el.scrollIntoViewIfNeeded()}
        },
        onBlur: () => this.dropdownVisible = false,
        onSelect: (index) => {
          const elem = values[index]
          if ('options' in elem) {
            this._toggle(...elem.options.map(o => o.key))
          } else {
            this._toggle(elem.key)
          }
        },
      },
    )
  }

  _onKeyup(event) {
    this.regexp = new RegExp(event.target.value, 'i')
  }

  _filterGroup(opt) {
    if (opt.options) {
      return {
        ...opt,
        options: opt.options.filter(option => this.regexp.test(option.label)),
      }
    }
    if (this.regexp.test(opt.label)) {
      return opt
    }
    return {options: []}
  }


  _toggleAllVisible() {
    const allVisibleItems = (
      this.slottedValues
        .map(g => this._filterGroup(g))
        .flatMap(e => e.options === undefined ? e : e.options)
        .filter(e => e.options === undefined)
        .map(({key}) => key)
    )
    this._toggle(...allVisibleItems)
  }

  get value() {
    return this.slottedValues
      .flatMap(option => option.options || option)
      .filter(item => this.selectedItems.has(item.key))
      .map(item => item.label)
      .sort()
      .join(', ')
  }

  render() {
    const className = `${this.class ?? '' } input is-clickable has-text-ellipsed is-fullwidth`

    return html`
      <link rel="stylesheet" href="${config.GLOBAL_STYLE_URL}"/>
      <slot style="display: none"></slot>

      <div class="dropdown is-flex-grow-1 ${this.dropdownVisible ? 'is-active' : ''} ${this.dropdownInfo.classPosition}" >
        <div class="dropdown-trigger is-flex is-flex-grow-1" @click="${this._toggleDropdown}">
          <div class="control is-flex is-flex-grow-1">
            <div class="control has-icons-right is-flex is-flex-grow-1">
              <input
                  class="${className}"
                  type="text"
                  .value=${this.value}
                  tabindex="${this.dropdownVisible ? -1 : 0}"
                  .placeholder=${this.placeholder}
                  readonly>
              <span class="icon is-right has-text-link">
                <i class="fa fa-bars"></i>
              </span>
            </div>
          </div>
        </div>

        <div class="dropdown-menu" role="menu" >
          <div class="dropdown-content py-0">
            <div class="dropdown-item pt-3" id="search-bar">
              <div class="field has-addons">
                <div class="control">
                  <button class="button is-small" @click=${this._toggleAllVisible} data-testid="toggle-all">
                    <span class="icon is-small">
                      <i class ="fas fa-check-circle"></i>
                    </span>
                  </button>
                </div>
                <div class="control is-flex is-flex-grow-1">
                  <input class="input is-small" role="search" type="text" @keydown="${this._onKeydown}" @keyup=${this._onKeyup}>
                </div>
              </div>
            </div>
            <div style="overflow-y: scroll; max-height: ${this.dropdownInfo.availableSpace}px" id="items">
              ${
                this.dropdownVisible
                  ? this.slottedValues
                    .map(g => this._filterGroup(g))
                    .filter(e => e.options === undefined || e.options.length) // filter out empty groups
                    .flatMap(e => e.options ? [e, ...e.options] : e)
                    .map((e, idx) => this._renderSlotTemplate(e, idx))
                  : html``
              }
            </div>
          </div>
        </div>
      </div>
    `
  }

  _renderSlotTemplate({key, label, options}, idx) {
    const selectedClass = idx === this.index ? 'is-active': ''

    if (options) {
      return html`
        <a class="dropdown-item has-text-ellipsed has-text-weight-semibold ${selectedClass}" 
           title=${label} 
           @click=${() => this._toggle(...options.map(o => o.key))}>
          ${label}
        </a>
      `
    }

    const textColor = idx === this.index ? '' : 'has-text-link'
    return html`
      <label>
        <a class="dropdown-item has-text-ellipsed ${selectedClass}" title=${label} @click=${() => this._toggle(key)}>
          <span class="icon is-small ${textColor}">
            ${
              this.selectedItems.has(key)
                ? html`<i class="fas fa-square"></i>`
                : html`<i class="far fa-square"></i>`
            }
          </span>
          ${label}
        </a>
      </label>
    `
  }
}
