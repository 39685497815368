
/**
  * Calculate available free space to the edges of the screen
  */
function getAvailableSpace($el, margin=8) {
  const boundingRect = $el.getBoundingClientRect()
  const viewportHeight = window.innerHeight

  return {
    below: Math.max(viewportHeight - boundingRect.bottom - margin, 0),
    above: Math.max(boundingRect.top - margin, 0),
    // left: Math.max(boundingRect.left, 0),
    // right: Math.max(window.visualViewport.width - boundingRect.right, 0),
  }
}

export default {
  getAvailableSpace,
}
