function firstScrollingParent($el) {
  if ($el === null || $el === undefined) {
    return $el
  }
  if ($el.style.overflowY === 'scroll') {
    return $el
  }
  return firstScrollingParent($el.parentNode)
}

// adjust closest scrollable parent by the minimum amount required to show the
// target element
Element.prototype.scrollIntoViewIfNeeded = function() {
  const boundingRect = this.getBoundingClientRect()
  const scrollingParent = firstScrollingParent(this)
  const parentBounds = scrollingParent.getBoundingClientRect()

  if (boundingRect.bottom > parentBounds.bottom) {
    const toScroll = boundingRect.bottom - parentBounds.bottom
    scrollingParent.scrollBy(0, toScroll)
  } else if (boundingRect.top < parentBounds.top) {
    const toScroll = boundingRect.top - parentBounds.top
    scrollingParent.scrollBy(0, toScroll)
  }
}
